import dhl from '../../../../../images/carriers/dhl.png';
import txt from '../../../../../images/carriers/txt.png';
import mrw from '../../../../../images/carriers/mrw.png';
import seur from '../../../../../images/carriers/seur.png';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './shippingOptions.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChangeShipping } from '../../../../../redux/checkout';
import Spinner from '../shared/components/Spinner';

const ShippingOptions = ({ part, options, selected, boxNum, totalParts }) => {
    const [isSelected, setIsSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [optionsArr, setOptionsArr] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const checkoutInfo = useSelector((state) => {
        return state.checkout.entity;
    });

    const carriers = {
        12: { src: seur, time: '48/72' },
        17: { src: mrw, time: '24/48' },
        19: { src: txt, time: '48/72' },
        22: { src: dhl, time: '24/48' },
    };

    const handleShippingService = async (part, selectedOption) => {
        setIsLoading(selectedOption.servicio_envio_id);
        setIsSelected(null);
        const newOptions = checkoutInfo?.shipping_options?.map((op) =>
            op.part.id === part ? { ...op, selected: selectedOption } : op
        );
        const res = await dispatch(
            fetchChangeShipping(
                checkoutInfo.presupuesto.id,
                part.id,
                selectedOption,
                newOptions
            )
        );
        if (!res) {
            return setIsLoading(false);
        }
        setIsLoading(false);
        setIsSelected(selectedOption.servicio_envio_id);
    };

    useEffect(() => {
        setIsSelected(selected.servicio_envio_id);
    }, [selected]);

    useEffect(() => {
        if (options.length === 1 && options[0].transportista_id === 1) {
            return setOptionsArr(options);
        }
        setOptionsArr([
            ...options,
            {
                transportista_id: 1,
                servicio_envio_id: 57,
                nombre_servicio: 'RECOGE',
                precio_transporte: 2,
            },
        ]);
    }, []);

    return (
        <div className="shipping-options" key={part.id + boxNum}>
            {totalParts > 1 && (
                <p className="shipping-options__title">
                    {t('carrito.shipping.box', { boxNum })} {`(${part.part})`}:
                </p>
            )}
            <div className="shipping-options__container">
                {optionsArr.map((op) => (
                    <div
                        key={op.servicio_envio_id + boxNum}
                        className={`shipping-options__container--card ${
                            isSelected === op.servicio_envio_id
                                ? 'selected'
                                : ''
                        }`}
                    >
                        <div className="shipping-container">
                            <div className={`shipping-container__subcontainer subcontainer-text ${isSelected === op.servicio_envio_id ? 'selected' : ''}`}>
                                <span
                                    className={
                                        op.transportista_id === 1
                                            ? 'recoge-cliente'
                                            : ''
                                    }
                                >
                                    {op.transportista_id !== 1 ? (
                                        <img
                                            alt={op.nombre_servicio}
                                            src={
                                                carriers[op.transportista_id]
                                                    ?.src
                                            }
                                        />
                                    ) : (
                                        <span className="recoge-title">
                                            {t('carrito.shipping.recoge')}
                                        </span>
                                    )}
                                </span>
                                <span>{op.precio_transporte?.toFixed(2)}€</span>

                                <span className="shipping-time">
                                    {op.transportista_id !== 1
                                        ? t('carrito.shipping.time', {
                                              horas: carriers[
                                                  op.transportista_id
                                              ]?.time,
                                          })
                                        : 'C/Marinada 12, 25600 Balaguer 9:00-14:00/15:00-18:00'}
                                </span>
                            </div>
                            <div className='shipping-container__subcontainer'>
                                {isLoading === op.servicio_envio_id ? (
                                    <div className="shipping-container-spinner">
                                        <Spinner />
                                    </div>
                                ) : isSelected !== op.servicio_envio_id ? (
                                    <span
                                        className="shipping-options__container--card--select"
                                        onClick={() =>
                                            handleShippingService(part, op)
                                        }
                                    >
                                        {t('carrito.shipping.select')}
                                    </span>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ShippingOptions;
