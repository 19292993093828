import { toast } from "react-toastify";
import { setErrorMessage } from "../shared/helpers/functionalities";
import { aplicarDescuentoCheckout, changeShippingService, getCheckoutInfo, getShippingPrice, newDireccionClienteCheckout, recalculatePresupuesto, saveNotasInfo, saveSelectedDireccionCheckout, updateClienteCheckout, updateDireccionClienteCheckout, updateDireccionClienteCheckoutEc } from "../shared/services/checkout";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "./utils";

const initialCheckout = { 
    presupuesto: {},
    cliente: {},
    has_ventas: 0,
    direcciones: [],
    poblaciones: [],
    paises: [],
    tipo_clientes: [],
    is_venta: null,
    telefono_recambista: null,
    selected_direccion_id: null
};

export const checkoutReducer = (state = initialCheckout, action) => {
    switch(action.type){
        case "checkout/fullfilled": {
            if(action.payload.action === 'total') {
                const newCheckout = {...action.payload.item};
                return newCheckout;
            } else if(action.payload.action === 'cliente') {
                const newCheckout = {...state,  cliente: action.payload.item, direcciones: action.payload.item.direcciones }
                return newCheckout;
            } else if(action.payload.action === 'new-direccion') {
                const newDirecciones = state.direcciones;
                newDirecciones.push(action.payload.item);

                return {
                    ...state, 
                    direcciones: newDirecciones,
                    selected_direccion_id: action.payload.item.id 
                }
            } else if(action.payload.action === 'update-direccion') {
                const newDirecciones = state.direcciones.map(dir => {
                    if(dir.id === action.payload.item.id) {
                        return  action.payload.item
                    }
    
                    return dir;
                })

                return {
                    ...state,
                    direcciones: newDirecciones,
                    selected_direccion_id: action.payload.item.id
                }
            } else if(action.payload.action === 'update-notas') {
                return {
                    ...state,
                    presupuesto: {
                        ...state.presupuesto,
                        observaciones_cliente: action.payload.observaciones_cliente
                    }
                }
            }    
        }
        default: return state
    }
}

const asyncCheckout = makeAsyncTypes('checkout');

export const [setPending, setFullFilled, setError ] = asyncMac(asyncCheckout);

export const checkoutFetchingReducer = makeFetchingReducer([
    'checkout/pending', 
    'checkout/fullfilled', 
    'checkout/rejected'
]);

export const fetchCheckout = (checkoutId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getCheckoutInfo(checkoutId)
        .catch(function (error) {
            toast.error("Ha habido un problema.")
        });
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchUpdateClienteCheckout = (checkoutId, cliente) => async dispatch => {
    try {
        const response = await updateClienteCheckout(checkoutId, cliente)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
        });
        const data = await response.data;
        await dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const fetchNewDireccionCheckout = (checkoutId, direccion) => async dispatch => {
    try {
        const response = await newDireccionClienteCheckout(checkoutId, direccion)
        .catch(function (error) {
            toast.error(setErrorMessage(error.message))
        });
        const data = await response.data;
        await dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message));
        toast.error(e.message);
    }
}

export const fetchUpdateDireccionCheckout = (checkoutId, direccion) => async dispatch => {
    try {
        const response = await updateDireccionClienteCheckout(checkoutId, direccion)
        .catch(function (error) {
            toast.error(setErrorMessage(error.message))
        });
        const data = await response.data;
        dispatch(setFullFilled(data));
        return data;
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const fetchUpdateDireccionCheckoutEc = (checkoutId, direccion) => async dispatch => {
    try {
        const response = await updateDireccionClienteCheckoutEc(checkoutId, direccion)
        .catch(function (error) {
            toast.error(setErrorMessage(error.message))
        });
        const data = await response.data;
        if(data.item.id === direccion.id) {
            dispatch(setFullFilled(data));
        }
        return data;
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const fetchSaveSelectedDireccion = (checkoutId, direccionId) => async dispatch => {
    try {
        const response = await saveSelectedDireccionCheckout(checkoutId, { direccion_envio_id: direccionId})
        .catch(function (error) {
            toast.error(setErrorMessage(error))
          });
        const data = await response.data;
        dispatch(setFullFilled(data));
        toast.success(response.message);
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const fetchApplyDiscount = (checkoutId, valeDescuentoId) => async dispatch => {
    try {
        const response = await aplicarDescuentoCheckout(checkoutId, { vale_descuento_id: valeDescuentoId})
        .catch(function (error) {
            toast.error(setErrorMessage(error))
          });
        const data = await response.data;
        dispatch(setFullFilled(data));
        toast.success(response.message);
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const checkoutStatusReducer = checkoutFetchingReducer;

export const fetchShippingPrice = (presupuesto_id, direccion) => async dispatch => {
    try {
        const response = await getShippingPrice({presupuesto_id, direccion})
        .catch(function (error) {
            toast.error(setErrorMessage(error))
          });
        const data = await response.data;
        dispatch(setFullFilled(data));
        toast.success(response.message)
        return data
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const fetchChangeShipping = (
    presupuesto_id,
    part_id,
    shipping_selected,
    newOptions
) => async dispatch => {
    try {
        const response = await changeShippingService({
            presupuesto_id,
            part_id,
            shipping_selected
        })
        .catch(function (error) {
            toast.error(setErrorMessage(error))
          });
        const data = await response.data;
        data.item.shipping_options = newOptions;
        dispatch(setFullFilled(data));
        return data
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const fetchRecalculatePresupuesto = (presupuesto_id, checkout_id) => async dispatch => {
    try {
        const response = await recalculatePresupuesto({presupuesto_id, checkout_id})
        .catch(function (error) {
            toast.error(setErrorMessage(error))
          });
        const data = await response.data;
        dispatch(setFullFilled(data));
        toast.success(response.message)
        return data
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}

export const fetchSaveNotasInfo = (infoNotas) => async dispatch => {
    try {
        const response = await saveNotasInfo(infoNotas)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
          });
        const data = await response.data;
        dispatch(setFullFilled(data));
        return data
    } catch (e) {
        dispatch(setError(e.message))
        toast.error(e.message);
    }
}