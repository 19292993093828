import { useEffect, useState } from 'react';
import './modalClose.scss';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { handleValidPhone } from '../../../../../utils';
import { sendClientMessage } from '../../../../../shared/services/checkout';
import { useSelector } from 'react-redux';
import { setErrorMessage } from '../../../../../shared/helpers/functionalities';
import Spinner from '../shared/components/Spinner';
import { useTranslation } from 'react-i18next';

const ModalClose = ({ closeModal }) => {
    const checkoutInfo = useSelector((state) => {
        return state.checkout.entity;
    });
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [contactInfo, setContactInfo] = useState({
        name: '',
        phone: '',
        problem: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(isLoading) return;
        setIsLoading(true);
        try {
            const res = await sendClientMessage({
                ...contactInfo,
                presupuesto: checkoutInfo.presupuesto.numero,
            })
            if (res?.success) {
                toast.success(
                    t('carrito.modal-close.thanks')
                );
                closeModal();
            }
        } catch (error) {
            toast.error(setErrorMessage(error))
        } finally {
            setIsLoading(false);
        }
        
    };

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 100);
    }, []);

    return (
        <div className="modal-close" onClick={closeModal}>
            <div
                className={`modal-close__content ${isVisible ? 'visible' : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <span
                    className="modal-close__content--close"
                    onClick={closeModal}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </span>
                <h3>{t('carrito.modal-close.title')}</h3>
                <p>
                    {t('carrito.modal-close.description')}
                </p>

                <form className="contact-info" onSubmit={handleSubmit}>
                    <div className="contact-info__container-input">
                        <label htmlFor="name">{t('carrito.modal-close.name')}</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={contactInfo.name}
                            onChange={handleInputChange}
                            required
                            minLength={5}
                        />
                    </div>
                    <div className="contact-info__container-input">
                        <label htmlFor="email">{t('carrito.modal-close.phone')}</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={contactInfo.phone}
                            onChange={handleInputChange}
                            required
                            onKeyDown={handleValidPhone}
                        />
                    </div>

                    <div className="contact-info__container-input">
                        <label htmlFor="problem">{t('carrito.modal-close.problem')}</label>
                        <textarea
                            id="problem"
                            name="problem"
                            placeholder={t('carrito.modal-close.message-placeholder')}
                            value={contactInfo.problem}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='contact-info__container-btn'>
                        <button type="reset" className="cancel-button" onClick={closeModal}>
                            {t('carrito.modal-close.cancel')}
                        </button>
                        <button type="submit" className="submit-button">
                            {isLoading ? <Spinner /> : t('carrito.modal-close.submit')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModalClose;
