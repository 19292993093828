import React, { useEffect, useState } from "react";
import PlacesAutocomplete, 
{ geocodeByAddress,
geocodeByPlaceId } from "react-places-autocomplete";
import { toast } from "react-toastify";
import './autocomplete.scss';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';


export default function GooglePlacesAutocomplete({
    defaultValue,
    country,
    disabled,
    onSelectResult,
    watch,
    setError,
    clearErrors,
    errors
}) {
    const { t } = useTranslation()
    const [selected, setSelected] = useState('');

    useEffect(() => {
      if(defaultValue) {
        setSelected(defaultValue);
      }
    }, [country, defaultValue, disabled]);

    const handleSelectPlace = (place) => {
        setSelected(place);
    }

    const findComponent = (source, type) => 
      source?.address_components?.find(c => c.types.includes(type))?.long_name || null;

    const extractData = (source) => ({
        extra: findComponent(source, 'subpremise') || '',
        num: findComponent(source, 'street_number'),
        street: findComponent(source, 'route'),
        postalCode: findComponent(source, 'postal_code'),
        city: findComponent(source, 'locality'),
        adminArea: findComponent(source, 'administrative_area_level_1'),
        adminArea2: findComponent(source, 'administrative_area_level_2'),
        countryName: findComponent(source, 'country'),
    });

    const handleSelect = async (address, placeId, id) => {
      if(errors.direccion) clearErrors('direccion')
      const [place] = await geocodeByPlaceId(placeId);

      let data = extractData(place);

      if (Object.values(data).some((value) => value === null)) {
        const [results] = await geocodeByAddress(address);
        const fallbackData = extractData(results);

        const getMoreOptions = (options) => {
          return options.reduce((value, option) => value || findComponent(place, option), null);
        };

        data = {
            ...data,
            num: data.num || fallbackData.num,
            street: data.street || fallbackData.street,
            postalCode: data.postalCode || fallbackData.postalCode,
            city: data.city || fallbackData.city
                || getMoreOptions(['administrative_area_level_3', 'sublocality', 'postal_town']),
            adminArea: data.adminArea || fallbackData.adminArea,
            adminArea2: data.adminArea2 || fallbackData.adminArea2
                || getMoreOptions(['administrative_area_level_3', 'locality', 'postal_town']),
            countryName: data.countryName || fallbackData.countryName,
        };
      }

      const { extra, num, street, postalCode, city, adminArea, adminArea2, countryName } = data;

      if(!street || !postalCode || !city || !adminArea2 || !num) {
        if(!num && !postalCode) {
          toast.warning(t('errors.google-places.num'));
          return;
        }
        if(!street || !postalCode || !adminArea2) {
          toast.warning(t('errors.google-places.street'));
          return;
        }
      }
      const selectedAddress = street + (num ? `, ${num}` : '') + (extra ? ` ${extra}` : '')
      setSelected(selectedAddress)
 
      onSelectResult({
        direccion: place.types.find(t => t === 'establishment') ? `${street}, ${num} ${extra}` : selectedAddress,
        codigo_postal: postalCode,
        poblacion: city,
        area: `${adminArea2 || city || countryName}, ${adminArea || countryName}`
      })
    };

    const handleOnBlur = () => {
      if(!watch('codigo_postal')) {
        setSelected('')
        return setError('direccion', 'select-option')
      }
      clearErrors('direccion')
    }
    
    return (
        <PlacesAutocomplete
          value={selected}
          searchOptions={{
            types: ['street_address', 'route', 'car_dealer', 'car_rental', 'car_repair'],
            language: 'es',
            componentRestrictions: {
              country: [country],
            }
          }}
          onChange={handleSelectPlace}
          onSelect={handleSelect}
          onError={(status, clearSuggestions) => {
            if(status === 'ZERO_RESULTS') {
              console.log('No hay resultados');
              toast.warning('No hay resultados');
            } else {
              console.log('Google Maps API returned error with status: ', status)
              toast.error('Google Maps API returned error with status: ', status)
            }
            
            clearSuggestions()
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="places-search form-input large">
              <input
                id='dir-direccion'
                {...getInputProps({
                  disabled: disabled
                })}
                required
                autoComplete="new-password"
                onBlur={handleOnBlur}
              />
              <label htmlFor='dir-direccion'>
                {t('carrito.envio.form.direccion')} *
              </label>
              <div className="places-search-container">
                {loading && <div className="suggestion-item">Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? 'suggestion-item-active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={'place-suggestion-' + i}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
              {errors.direccion && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t('carrito.envio.form.errors.direccion')}</span>}
            </div>
          )}
        </PlacesAutocomplete>
      );
}